let items = [
  {
    name: "CP-Q8BF",
    title: "CP-Q8BF",
    price: "10,000",
    downloadAddress: `${window.location.origin}/files/CP-Q8BF-DM.pdf`,
    images: [
      {
        url: require("@/assets/images/merch/printer/CP-Q8BF.webp"),
      },
    ],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "300 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "576 dots/line，203 dpi" },
      { title: "介面", text: "三合一USB + RS232 + LAN" },
      { title: "尺寸", text: "L 183 x W 143.5 x H 135 mm" },
      { title: "重量", text: "1.5 kg" },
    ],
  },
  {
    name: "HPRT-TP805L",
    title: "HPRT TP805L",
    price: "10,000",
    images: [
      { url: require("@/assets/images/merch/printer/HPRT-TP805L.webp") },
    ],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "Max. 250 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "203 dpi" },
      { title: "介面", text: "USB + Serial + Ethernet" },
      { title: "尺寸", text: "L 195 x W 147 x H 147 mm" },
      { title: "重量", text: "1.8 kg" },
    ],
  },
  {
    name: "Itbs-IUP900",
    title: "Itbs IUP900",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/printer/Itbs-IUP900-27.webp"),
      },
    ],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "260 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "203 dpi" },
      { title: "介面", text: "三合一USB(HID) + RS232 + Ethernet" },
      { title: "錢櫃連接埠", text: "1*RJ-11 / DC 24V Output" },
      { title: "尺寸", text: "L 250 x W 200 x H 162 mm" },
      { title: "重量", text: "2.2 kg" },
    ],
  },
  {
    name: "Prowill-PD-S8252X",
    title: "Prowill PD-S8252X",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/printer/Prowill-PD-S8252X-26.webp"),
      },
    ],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "260 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "640 dots/line" },
      { title: "介面", text: "USB + RS232 + LAN" },
      { title: "尺寸", text: "L 162 x W 136 x H 130 mm" },
      { title: "重量", text: "1.12 kg" },
    ],
  },
  {
    name: "Prowill-PD-X326",
    title: "Prowill PD-X326",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/printer/Prowill-PD-X326.webp"),
      },
    ],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "250 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "203 dpi" },
      { title: "介面", text: "RS232 + USB + LAN (三合一)" },
      { title: "尺寸", text: "L 142 x W 195 x H 142 mm" },
      { title: "重量", text: "1.5 kg" },
    ],
  },
  {
    name: "QM3",
    title: "QM3",
    price: "NT 10,000",
    images: [{ url: require("@/assets/images/merch/printer/QM3.webp") }],
    specs: [
      { title: "紙張寬度", text: "Max. 80 mm" },
      { title: "列印寬度", text: "Max. 72 mm" },
      { title: "列印速度", text: "300 mm/秒" },
      { title: "指令", text: "ESC/POS" },
      { title: "解析度", text: "576 dots/line" },
      {
        title: "介面（三擇一）",
        text: "（1）USB + Serial + LAN（2）USB + Serial + LAN + Bluetooth（3）USB + Serial + LAN + Wi-Fi",
      },
      { title: "尺寸", text: "W 130 x H 133 x D 140 mm" },
      { title: "重量", text: "1.1 kg" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
