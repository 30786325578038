let items = [
  {
    name: "CASIO-DT-970",
    title: "CASIO DT-970",
    price: "10,000",
    illustrates: [
      "工作場所最適用的手持終端設備",
      "易於使用及操作，左右手皆方便單手抓握",
    ],
    images: [
      {
        url: require("@/assets/images/merch/inventorymachine/CASIO-DT-970.webp"),
      },
    ],
    intros: [
      { main: "三個掃描觸發鍵，確保可靠且方便的掃描" },
      { main: "跌落防摔設計：從高達2米處跌落，也可放心使用" },
      { main: "防水防塵設計：符合IP67標準" },
      { main: "不受環境限制：可在低至零下20度的狀況運行" },
      { main: "大型背光鍵盤：在黑暗環境也能無誤的操作" },
      { main: "LCD背光顯示螢幕，發生錯誤時，螢幕背光可以設置為閃爍紅色" },
      { main: "電池續航力：兩節 AA 型鹼性電池，可連續工作 200 小時" },
      { main: "應用模型選擇：直線掃描 / 向下掃描" },
    ],
    specs: [
      {
        title: "介面",
        text: "Bluetooth® + Infrared + Card Slot + USB (Micro-B)",
      },
      { title: "尺寸", text: "W 54.0 x H 25.0 x D 173.5 mm" },
      { title: "重量", text: "215 g" },
    ],
  },
  {
    name: "BCP-8000",
    title: "盤點機",
    price: "10,000",
    illustrates: ["內建輔助電池與資料比對功能", "確保資料不流失、檢核更有效率"],
    images: [
      {
        url: require("@/assets/images/merch/inventorymachine/BCP-8000.webp"),
      },
    ],
    intros: [
      { main: "人體工學造型設計，適合長時間操作" },
      { main: "高效能雷射掃描模組，快速掃描、判讀精準" },
      { main: "適合倉儲、物料管理使用" },
      { main: "高亮度藍色背光顯示螢幕，閱讀清晰" },
      { main: "專用充電 / 傳輸兩用底座" },
      { main: "專用Job Generator資料管理軟體，規劃作業更便捷" },
      { main: "長效1700 mA鋰電池，長時間作業免充電" },
      { main: "資料比對功能（Look-up function）資料比對、檢核更迅速" },
      { main: "資料保護：輔助電池（Auxiliary Battery）保護資料不流失" },
      { main: "支援作業系統：Windows（2000 / XP / Vista / Windows 7）" },
      { main: "介面：RS232 或 USB（Virtual RS232）" },
      { main: "尺寸：W 145 x H 31 x D 64 mm" },
      { main: "重量：175 g" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
