let items = [
  {
    name: "Timecards",
    title: "各式考勤卡",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/timecard/Timecard.webp"),
      },
    ],
    intros: [
      { main: "考勤卡 / 出勤記錄卡 / 打卡鐘卡片" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
  {
    name: "Timecard-Holder",
    title: "考勤卡架",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/timecard/Timecard-Holder.webp"),
      },
    ],
    intros: [
      { main: "組合式鐵架，打卡卡架 / 考勤卡架" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
