import { createApp } from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
import "bootstrap";
import { createHead } from "@vueuse/head";

import App from "./App";
import router from "./router";
import store from "./store";
import products from "@/datas/index";
import enums from "@/enums";
import apis from "@/request/index";
import funcs from "@/funcs";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

/* add icons to the library */
library.add(fas);
library.add(fab);
library.add(far);

const app = createApp(App);

app.config.globalProperties.$product = products;
app.config.globalProperties.$enum = enums;
app.config.globalProperties.$api = apis;
app.config.globalProperties.$func = funcs;
app.use(VueAxios, axios);
app.use(createHead());
app.use(router);
app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
