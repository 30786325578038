let items = [
  {
    name: "Cashboxes",
    title: "各式錢櫃",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/cashbox/Cashbox.webp"),
      },
    ],
    intros: [
      { main: "具紙鈔格及硬幣格" },
      { main: "尺寸：小 － 36 x 36 cm / 大 － 42 x 42 cm" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
