let items = [
  {
    name: "DP-105",
    title: "DP-105磅秤",
    price: "10,000",
    illustrates: [
      "具自動校正、自動歸零、全段扣重、低電量警示、自動省電、充電指示、加法及乘法運算功能",
    ],
    images: [
      {
        url: require("@/assets/images/merch/scale/DP-105.webp"),
      },
    ],
    intros: [
      { main: "IP66 防水等級，適合潮濕環境使用" },
      { main: "LCD 螢幕顯示，具背光輔助照明" },
      { main: "內建蓄電池，可供 100 小時不斷電操作" },
      { main: "封閉式電子迴路，內含防潮指示劑" },
      { main: "可執行累加 / 重示 / 記憶清除等功能" },
      { main: "重量單位：公斤 / 台斤 / 兩 / 100公克，可供轉換" },
      { main: "具備三段式精度" },
      { main: "秤量 / 解析度（三段式）" },
      { main: "重量：30 ~ 105 kg / 50 g" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
