let items = [
  {
    name: "WP-560",
    title: "WP-560 二聯式發票機",
    price: "10,000",
    illustrates: ["與 UM88 搭配，簡單開立二聯發票"],
    images: [
      {
        url: require("@/assets/images/ecr/duplicateuniforminvoice/WinPOS-WP-560.webp"),
      },
    ],
    intros: [
      { main: "可同時列印存根聯及收執聯，具自動切紙功能" },
      { main: "外接埠可連接錢櫃，不需安裝驅動程式" },
      { main: "採用日本 Citizen 機心，穩定輸出、故障率低" },
      { main: "自動偵測卡紙、高速雙向列印" },
      { main: "定位異常、紙捲用罄將以燈號及聲響警示" },
      { main: "本機器需與 POS 系統或軟體搭配用，無法單獨使用" },
      { main: "內含中文字 Big-5 碼，每秒可列印 3 行，硬體具一年保固" },
    ],
    specs: [
      {
        title: "列印方式",
        text: "9針點矩陣雙向列印",
      },
      {
        title: "列印速度",
        text: "3行/秒",
      },
      {
        title: "送紙速度",
        text: "30行/秒",
      },
      {
        title: "指令",
        text: "ESC/POS、TP-3688、TP-6688及HP-2000 指令相容",
      },
      {
        title: "狀態感應器",
        text: "自動偵測卡紙、定位異常、發票用盡",
      },
      {
        title: "介面",
        text: "RS232 + Parallel",
      },
      {
        title: "尺寸",
        text: "W 200.0 x H 230.0 x D 278.9 mm",
      },
      {
        title: "重量",
        text: "4.7 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
