let items = [
  {
    name: "Roll-Of-Papers",
    title: "各式紙捲",
    price: "10,000",
    illustrates: ["收銀結帳 / 模擬紙捲 / 出單 / 收據 / 電子發票"],
    images: [
      {
        url: require("@/assets/images/merch/rollofpaper/InvoiceRoll.webp"),
      },
      {
        url: require("@/assets/images/merch/rollofpaper/InvoiceRoll-2.webp"),
      },
      {
        url: require("@/assets/images/merch/rollofpaper/InvoiceRoll-3.webp"),
      },
    ],
    intros: [
      { main: "發票紙捲、出單紙捲、結帳紙、空白黑點紙捲等" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
