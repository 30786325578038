let items = [
  {
    name: "Paiyang-P268-P368",
    title: "Paiyang P268 數字型支票機 / P368 國字型支票機",
    price: "10,000",
    version: ["P268 數字型支票機", "P368 國字型支票機"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/checkmachine/Paiyang-P268-P368.webp"),
      },
    ],
    intros: [
      { main: "開立張數，金額可記憶顯示" },
      { main: "金額輸錯：退位功能" },
      { main: "打印錯誤：刪除作廢功能" },
    ],
    specs: [
      {
        title: "印字數量",
        text: "P268：10 位數 / P368：10 位數",
      },
      {
        title: "尺寸",
        text: "L 245 x W 118 x H 140 mm",
      },
      {
        title: "重量",
        text: "1.8 kg",
      },
    ],
  },
  {
    name: "Paiyang-P568-P668",
    title: "Paiyang P568 數字型支票機 / P668 國字型支票機",
    price: "10,000",
    version: ["P568 數字型支票機", "P668 國字型支票機"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/checkmachine/Paiyang-P568-P668.webp"),
      },
    ],
    intros: [
      { main: "打印字體：數字 / 國字" },
      { main: "數位超大液晶顯示窗" },
      { main: "同樣金額，可重複打出" },
      { main: "具看門狗軟體，可自動排除當機" },
      { main: "印字方式：刻字鋼輪前後、回轉式打字" },
    ],
    specs: [
      {
        title: "印字數量",
        text: "P568：10 位數 / P668：10 位數",
      },
      {
        title: "尺寸",
        text: "L 245 x W 118 x H 140 mm",
      },
      {
        title: "重量",
        text: "1.8 kg",
      },
    ],
  },
  {
    name: "W3000",
    title: "世尚 W3000 數字型 / 國字型支票機",
    price: "10,000",
    version: ["W3000 數字型支票機", "W3000 國字型支票機"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/checkmachine/W3000.webp"),
      },
    ],
    intros: [
      { main: "數位超大液晶顯示窗" },
      { main: "打印錯誤：刪除作廢功能" },
      { main: "開立張數、總金額可記憶顯示" },
      { main: "視窗式定位，直接放入視窗" },
    ],
    specs: [
      {
        title: "印字數量",
        text: "數字 / 國字 10 位數",
      },
      {
        title: "尺寸",
        text: "L 250 x W 130 x H 100 mm",
      },
      {
        title: "重量",
        text: "2.5 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
