let items = [
  {
    name: "Carbon-Ribbon",
    title: "各式碳帶",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/carbonribbon/carbon-ribbon.webp"),
      },
    ],
    intros: [
      { main: "各式發票機碳帶" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
