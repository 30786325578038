let items = [
  {
    name: "BoJing-BJ50",
    title: "BoJing BJ50 數幣機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/coincountingmachine/BoJing-BJ50.webp"),
      },
    ],
    specs: [
      {
        title: "置幣容量",
        text: "約 2400 枚",
      },
      {
        title: "計算速度",
        text: "2150 枚 / 分",
      },
      {
        title: "硬幣直徑",
        text: "14～34 mm",
      },
      {
        title: "硬幣厚度",
        text: "1.0～3.4 mm",
      },
      {
        title: "記數顯示",
        text: "5 位數 LED 顯示",
      },
      {
        title: "設定範圍",
        text: "4 位數 (1～9999)",
      },
      {
        title: "尺寸",
        text: "L 370 x W 255 x H 185 mm",
      },
      {
        title: "重量",
        text: "8 kg",
      },
    ],
  },
  {
    name: "MT-800",
    title: "MT 800 商業型分幣機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/coincountingmachine/MT-800.webp"),
      },
    ],
    intros: [
      { main: "數幣、分幣、計數、計值、總計、預置數量一次完成" },
      { main: "具專用手提把，可分幣與數幣 (1、5、10、20、50 元)" },
      {
        main: "接幣盒容量：1元約 400 枚，5元約 300 枚，10元約 200 枚，20元約 80 枚，50元約 80 枚",
      },
    ],
    specs: [
      {
        title: "記數顯示",
        text: "4 位數 LED 顯示",
      },
      {
        title: "計算速度",
        text: "最大可達 270 枚 / 分",
      },
      {
        title: "尺寸",
        text: "L 345 x W 306 x H 260 mm",
      },
      {
        title: "重量",
        text: "3.9 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
