<template>
  <div class="h-100">
    <router-view></router-view>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
export default {
  name: "App",
  setup() {
    const siteData = reactive({
      title: "Ushow",
      description: "",
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description),
        },
      ],
    });
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    // fadeBackground,
  },
  beforeRouteUpdate() {
    this.isLoading = !this.isLoading;
  },
};
</script>

<style lang="scss">
@import "./assets/all";
#app {
  font-family: "PingFang", "Noto Sans TC", "Microsoft JhengHei", Avenir,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c2c34;
}
</style>
