let items = [
  {
    name: "Stickers",
    title: "各式貼紙",
    price: "10,000",
    illustrates: ["標籤貼 / 生鮮磅秤貼紙 / 抗刮抗凍"],
    images: [
      {
        url: require("@/assets/images/merch/sticker/Sticker.webp"),
      },
      {
        url: require("@/assets/images/merch/sticker/Sticker-2.webp"),
      },
      {
        url: require("@/assets/images/merch/sticker/Sticker-3.webp"),
      },
    ],
    intros: [
      { main: "各式銅板貼紙、生鮮磅秤貼紙" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
