let items = [
  //productsPart
  {
    name: "EInvoiceCashier",
    title: "電子發票收銀機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "DuplicateUniformInvoice",
    title: "二聯式發票機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "TriplicateUniformInvoice",
    title: "三聯式發票機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "BusinessMachine",
    title: "事務機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "MoneyCounter",
    title: "點驗鈔機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "PunchClock",
    title: "打卡鐘｜百揚資訊科技 Ushow POS",
  },
  {
    name: "PaperShredder",
    title: "碎紙機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "CheckMachine",
    title: "支票機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Laminator",
    title: "護貝機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "CoinCountingMachine",
    title: "數幣機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "PrintedClock",
    title: "印時鐘｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Stapler",
    title: "釘書機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "PosHost",
    title: "POS觸控主機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Printer",
    title: "熱感式出單機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "LabelingMachine",
    title: "標籤貼紙機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Scanner",
    title: "掃描器｜百揚資訊科技 Ushow POS",
  },
  {
    name: "InventoryMachine",
    title: "盤點機｜百揚資訊科技 Ushow POS",
  },
  {
    name: "ColorRibbon",
    title: "色帶｜百揚資訊科技 Ushow POS",
  },
  {
    name: "CarbonRibbon",
    title: "碳帶｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Sticker",
    title: "貼紙｜百揚資訊科技 Ushow POS",
  },
  {
    name: "RollOfPaper",
    title: "紙捲｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Cashbox",
    title: "錢櫃｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Scale",
    title: "磅秤｜百揚資訊科技 Ushow POS",
  },
  {
    name: "Timecard",
    title: "考勤卡｜百揚資訊科技 Ushow POS",
  },
  //電子發票機收銀機
  {
    name: "UM88",
    title:
      "Ushow UM88 電子發票收銀機｜機械式按鍵，簡單開立電子發票｜百揚資訊科技 Ushow POS",
    description:
      "複雜的事，簡單解決，餐飲業、飲料業、外燴業者的結帳管家。內建 SD 卡及客戶顯示器，具 16 項商品快捷鍵，點選商品數量即可快速結帳。紙捲盒透明上蓋，方便掌握用量及更換紙捲；抽屜式錢櫃具四層紙鈔格及四層硬幣格。可設定密碼及列印報表，發票自動取號配號，無需額外設定。",
  },
  {
    name: "UM99",
    title:
      "Ushow UM99 電子發票收銀機｜可掀式熱鍵插片，最多可設置 120 種商品｜百揚資訊科技 Ushow POS",
    description:
      "結帳管家再升級，食品零售、文教零售、綜合零售等業者的得力助手。電池將耗盡時自動跳出通知，內建客戶顯示器，具 120 項商品快捷鍵，業者能自訂商品。抽屜式錢櫃具三層紙鈔格及五層硬幣格。可設定密碼及列印報表，發票自動取號配號，無需額外設定。",
  },
  //二聯式發票機
  {
    name: "WP-560",
    title:
      "WP-560 二聯式發票機｜與 UM88 搭配，簡單開立二聯發票｜百揚資訊科技 Ushow POS",
    description:
      "與 UM88 搭配，簡單開立二聯式發票。可同時列印存根聯及收執聯，具自動切紙功能。自動偵測卡紙，高速雙向列印。定位異常、紙捲用罄以燈號及聲響警示，必須搭配 POS 系統或軟體使用、外接埠可接錢櫃。",
  },
  //三聯式發票機
  {
    name: "WP-103S",
    title: "WP-103S 三聯式發票機｜鏈齒三聯式複寫紙｜百揚資訊科技 Ushow POS",
    description:
      "列印鏈齒三聯式複寫紙，自動偵測卡紙、定位異常、發票用盡，以燈號及聲響警示，可串接客戶顯示器。",
  },
  {
    name: "ANICE-PP22",
    title:
      "ANICE-PP22 三聯式發票機｜隨機附贈發票管理程式｜百揚資訊科技 Ushow POS",
    description:
      "隨機附贈發票管理程式，內含收銀軟體。自動回捲裝置，存根聯可以收捲存放、可列印客戶名稱於發票、資料備份與回存、報表彙整轉存 Excel 功能。",
  },
  //事務機
  {
    name: "Panasonic_KX-MB2128TW_KX-MB2178TW",
    title:
      "Panasonic 雷射多功能事務機｜影印、列印、掃描｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Brother_MFC_L2740DW_L2700DW_L2700D",
    title:
      "Brother MFC 平台式雷射多功能事務機｜列印、掃描、影印、傳真｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Brother_FAX-2840",
    title:
      "Brother FAX-2840 平台式多功能複合機｜高品質列印、無紙接收傳真｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Epson_L565_Wi-Fi",
    title:
      "Epson L565 高速 Wi-Fi 六合一多功能複合機｜掃描、影印、列印、Wi-Fi、ADF 自動進紙、傳真｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //點驗鈔機
  // {
  //   name: "ANICE-GT5600",
  //   title:
  //     "ANICE-GT5600 兩國幣別鑑偽點驗鈔機｜點驗：台幣、人民幣｜百揚資訊科技 Ushow POS",
  //   description: "",
  // },
  // {
  //   name: "ANICE-GT9200",
  //   title:
  //     "ANICE-GT9200 台幣別鑑偽點驗鈔機｜點驗：台幣｜百揚資訊科技 Ushow POS",
  //   description: "",
  // },
  {
    name: "BoJing-AUTO-II",
    title:
      "BoJing AUTO II 全自動點驗鈔機｜點驗：台幣、人民幣｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-GT9500",
    title:
      "ANICE-GT9500 六國幣別鑑偽點驗鈔機｜點驗：台幣、人民幣、美金、歐元、日幣、港幣｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-GT9600",
    title:
      "ANICE-GT9600 智能型全功能台幣別點驗鈔機｜點驗：台幣｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-GT9800",
    title:
      "ANICE-GT9800 台幣別鑑偽點驗鈔機｜點驗：台幣｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //打卡鐘
  {
    name: "ANICE-GT3600",
    title:
      "ANICE GT3600 四欄位打卡鐘（單色）｜內建蓄電池及音樂響鈴｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-GT3600U",
    title:
      "ANICE GT3600U 四欄位打卡鐘（雙色）｜可黑紅變色，自動偵測卡面、吸卡及退卡｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "TR-301",
    title:
      "世尚 TR-301 六欄位打卡鐘｜遲到打印紅色，自動辨識卡面｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "TR-601",
    title:
      "世尚 TR-601 六欄位打卡鐘｜遲到打印紅色，自動辨識卡面，具萬年曆｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "JM-2010-6",
    title:
      "JM-2010-6 六欄位打卡鐘｜上下班自動移位，可設定密碼鎖定｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "JM-330",
    title:
      "JM-330 人臉指紋三合一考勤機｜內建智慧型統計軟體、高性能溫控指紋採擷器｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //碎紙機
  {
    name: "ROYAL-112MX",
    title:
      "ROYAL 112MX 經濟短碎型碎紙機｜可碎：光碟、信用卡｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Resun-C215",
    title:
      "ROYAL 112MX 經濟短碎型碎紙機｜可碎：光碟、信用卡｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ROYAL-1840MX",
    title:
      "ROYAL 1840MX 高保密短碎型碎紙機｜可碎：光碟、信用卡｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ROYAL-MC14MX",
    title:
      "ROYAL MC14MX 超高保密細碎型碎紙機｜可碎：光碟、信用卡、小型釘書針｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Resun-SA3000",
    title:
      "Resun SA3000 短碎型碎紙機｜可碎：光碟、信用卡｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "SYSFORM-3120S",
    title:
      "西德風 SYSFORM 3120S 短碎型碎紙機｜可碎：光碟、信用卡、釘書針、迴紋針｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "S280A-S300A-S443A",
    title:
      "西德風 SYSFORM 3120S 短碎型碎紙機｜可碎：光碟、信用卡、釘書針、迴紋針｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "S280A-S300A-S443A",
    title: "禾昌 長條型碎紙機｜可選擇規格｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "C280A-C300A-C443A",
    title: "禾昌 短碎型碎紙機｜可選擇規格｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //支票機
  {
    name: "Paiyang-P268-P368",
    title:
      "Paiyang P268 數字型支票機｜P368 國字型支票機｜適用於支票、提款單｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Paiyang-P568-P668",
    title:
      "Paiyang P568 數字型支票機｜P668 國字型支票機｜適用於支票、提款單｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "W3000",
    title:
      "世尚 W3000 數字型、國字型兩用支票機｜適用於支票、提款單｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //護貝機
  {
    name: "Resun-LM220-LM320",
    title:
      "Resun LM220(A4) 護貝機｜LM320(A3) 護貝機｜文件護貝、印刷品護貝｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //數幣機
  {
    name: "BoJing-BJ50",
    title: "BoJing BJ50 數幣機｜商用自動數幣機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "MT-800",
    title: "MT 800 商業型分幣機｜智慧型分幣機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //印時鐘
  {
    name: "KINGS-POWER-KP550",
    title: "KINGS POWER KP550 多功能印時鐘｜具萬年曆｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //釘書機
  {
    name: "MAX-BH11F",
    title: "MAX BH11F 電動釘書機｜攜帶型、輕巧低噪音｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "MAX-EH70F-II",
    title:
      "MAX EH70F II 電動釘書機｜大容量針盒、 具安全保護裝置｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "MAX-EH110F",
    title:
      "MAX EH110F 電動釘書機｜大量裝訂，節省工作時間｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //POS觸控主機
  {
    name: "ANICE-POS-TP-1615",
    title: "ANICE POS TP-1615｜POS觸控主機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-POS-PP-8400",
    title: "ANICE POS PP-8400｜POS觸控主機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-POS-TP-1715U",
    title: "ANICE POS TP-1715U｜POS觸控主機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "ANICE-POS-TP-7715",
    title: "ANICE POS TP-7715｜POS觸控主機｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //熱感式出單機
  {
    name: "CP-Q8BF",
    title:
      "CP-Q8BF 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "HPRT-TP805L",
    title:
      "HPRT TP805L 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Itbs-IUP900",
    title:
      "Itbs IUP900 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Prowill-PD-S8252X",
    title:
      "Prowill PD-S8252X 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Prowill-PD-X326",
    title:
      "Prowill PD-X326 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "QM3",
    title:
      "QM3 熱感式出單機｜電子發票、消費明細、收據、快速出單備餐｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //標籤機貼紙機
  {
    name: "HPRT-LPQ58",
    title:
      "HPRT LPQ58 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "GoDEX-DT2",
    title:
      "GoDEX DT2 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "LP-2",
    title: "LP-2 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "GoDEX-G500",
    title:
      "GoDEX G500 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "GoDEX-G530",
    title:
      "GoDEX G530 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "TSC-TTP-345",
    title:
      "TSC TTP-345 標籤機、貼紙機｜價格、品項、其他備註｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //掃描器
  {
    name: "RS-80DW/RS-80DB",
    title:
      "JMC RS 80DW / RS 80DB 掃描器｜手持式掃描器，可掃條碼、載具｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "SUNMI",
    title:
      "SUNMI 商米掃碼槍｜手持式掃描器，可掃條碼、載具｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "SUNMI-DESKTOP",
    title:
      "SUNMI 商米桌上型掃描器｜桌上型掃描器，可掃條碼、載具｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "RS-9D",
    title:
      "JMC RS-9D 掃描器｜手持式掃描器，可掃條碼、載具｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "M-6L",
    title:
      "JMC M 6L 桌上型掃描器｜桌上型掃描器，可掃條碼、載具｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //盤點機
  {
    name: "CASIO-DT-970",
    title:
      "CASIO DT-970 盤點機｜單手可抓握，具防摔、防水、防塵設計｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "BCP-8000",
    title:
      "BCP-8000 盤點機｜內建輔助電池與資料比對功能｜百揚資訊科技 Ushow POS",
    description: "",
  },
  //其他耗材
  {
    name: "Color-Ribbon",
    title:
      "各式色帶｜印表機耗材、多款印表機色帶，詳情請與窗口聯繫｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Carbon-Ribbon",
    title:
      "各式碳帶、墨輪、墨球｜標籤機碳帶、單據紙張耗材，詳情請與窗口聯繫｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Stickers",
    title:
      "各式貼紙｜標籤貼紙、銅板貼紙、生鮮貼紙等，詳情請與窗口聯繫｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Roll-Of-Papers",
    title:
      "各式紙捲｜電子發票、消費明細、收據、內外場點單等，詳情請與窗口聯繫｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Cashboxes",
    title: "各式錢櫃｜可選尺寸，詳情請與窗口聯繫｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "DP-105",
    title:
      "DP-105 磅秤｜自動校正、自動歸零、全段扣重、低電量警示。一般磅秤、生鮮磅秤｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Timecards",
    title: "各式考勤卡｜考勤卡、出勤記錄、打卡鐘卡片｜百揚資訊科技 Ushow POS",
    description: "",
  },
  {
    name: "Timecard-Holder",
    title: "考勤卡架｜鐵架、組合式｜百揚資訊科技 Ushow POS",
    description: "",
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

export default { getAll, getByName };
