let items = [
  {
    name: "RS-80DW/RS-80DB",
    title: "JMC RS-80DW & RS-80DB 掃描器",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/scanner/RS-80DW-RS-80DB.webp"),
      },
    ],
    intros: [{ main: "支援一維條碼及二維條碼" }, { main: "可加購掛架" }],
    specs: [
      { title: "光學影像感測器", text: "CMOS（640 x 480 像素）" },
      {
        title: "支援作業系統",
        text: "Linux、Android、Windows（XP / 7 / 8 / 10）、Mac",
      },
      { title: "掃描速度", text: "1300 次/秒" },
      { title: "連接埠", text: "USB（免安裝驅動）、RS232" },
      { title: "尺寸", text: "160 x 80 x 100 mm" },
      { title: "重量", text: "122 g" },
    ],
  },
  {
    name: "SUNMI",
    title: "SUNMI 商米掃碼槍",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/scanner/SUNMI.webp"),
      },
    ],
    intros: [
      { main: "高效能 CMOS 成像晶片" },
      { main: "支援一維條碼、二維條碼" },
      { main: "雙燈設計：柔白補光燈 + 紅色對焦燈，方便條碼瞄準" },
      { main: "蜂鳴提示、震動提示：隨時確認掃碼結果" },
      { main: "回彈按鍵：結實耐用的回彈式扳機按鈕" },
    ],
    specs: [
      {
        title: "支援作業系統",
        text: "Linux、Windows、iOS、Android",
      },
      { title: "畫素", text: "640 x 480" },
      { title: "識讀精度", text: "≥ 3 mil" },
      { title: "介面", text: "USB 2.0 Type A 公頭" },
      { title: "尺寸", text: "16 × 9.5 × 7.1 cm" },
    ],
  },
  {
    name: "SUNMI-DESKTOP",
    title: "SUNMI 商米桌上型掃描器",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/scanner/SUNMI-desktop.webp"),
      },
    ],
    intros: [
      { main: "支援二維條碼（QR Code）" },
      { main: "支援作業系統：Linux、Windows、iOS、Android" },
      { main: "LED指示：支付感應燈，執行掃描任務" },
      { main: "蜂鳴提示：確認掃碼成功" },
      { main: "外觀設計：45°人性化掃碼角度、鋁合金外框、開放式盒體設計" },
      { main: "識讀精度：二維 > 10 mil" },
      { main: "掃碼視窗：7.5 × 7.5 cm" },
      { main: "介面：USB公頭帶1.5米線" },
      { main: "尺寸：9.7 × 9 × 10 cm" },
    ],
  },
  {
    name: "RS-9D",
    title: "JMC RS-9D 掃描器",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/scanner/RS-9D.webp"),
      },
    ],
    intros: [{ main: "支援一維條碼、二維條碼" }, { main: "可加購掛架" }],
    specs: [
      { title: "連接埠", text: "USB、RS232" },
      { title: "尺寸", text: "145 x 101 x 68 mm" },
      { title: "重量", text: "110 g" },
      { title: "畫素", text: "640 x 480" },
      { title: "識讀精度", text: "≥ 3 mil" },
      { title: "介面", text: "USB 2.0 Type A 公頭" },
    ],
  },
  {
    name: "M-6L",
    title: "JMC M 6L 桌上型掃描器",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/scanner/M-6L.webp"),
      },
    ],
    intros: [
      { main: "雷射式" },
      { main: "支援一維條碼" },
      { main: "按鍵喚醒功能" },
      { main: "提示音：啟動提示、解碼成功提示" },
      { main: "紅藍雙色提示：紅燈代表供電；藍燈代表解碼成功" },
    ],
    specs: [
      { title: "支援作業系統", text: "Linux、Windows（XP / 7 / 8 / 10）" },
      { title: "掃描速度", text: "1120 次/秒" },
      { title: "連接埠", text: "USB（免安裝驅動）、RS232" },
      { title: "尺寸", text: "115 x 114 x 152 mm" },
      { title: "重量", text: "388 g" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
