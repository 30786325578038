export default function (name) {
  switch (name.toLowerCase()) {
    case "ushowpad":
      return "Ushow Pad";
    case "ushowlite":
      return "Ushow Lite";
    case "ushowmini":
      return "Ushow Mini";
    case "ushow":
      return "Ushow";
    case "ushowweb":
      return "Ushow Web";
    case "ushowcompare":
      return "POS系統比較";
    case "einvoice":
      return "電子發票";
    case "backstage":
      return "雲端後台";
    case "payment":
      return "多元支付";
    case "stock":
      return "庫存管理";
    case "customer":
      return "會員管理";
    case "onlineorder":
      return "線上點餐";
    case "einvoicecashier":
      return "電子發票收銀機";
    case "um88":
      return "UM88 電子發票收銀機";
    case "um99":
      return "UM99 電子發票收銀機";
    case "duplicateuniforminvoice":
      return "二聯式發票機";
    case "winpos-wp-560":
      return "WinPOS-WP-560 二聯式發票印表機";
    case "triplicateuniforminvoice":
      return "三聯式發票機";
    case "winpos-wp-103s":
      return "WinPOS-WP-103S 三聯式發票機";
    case "anice-pp22":
      return "ANICE-PP22 三聯式發票機";
    case "businessmachine":
      return "事務機";
    case "panasonic_kx-mb2128tw_kx-mb2178tw":
      return "Panasonic 雷射多功能事務機";
    case "brother_mfc_l2740dw_l2700dw_l2700d":
      return "Brother MFC 平台式雷射多功能事務機";
    case "brother_fax-2840":
      return "Brother FAX-2840 平台式多功能複合機";
    case "epson_l565_wi-fi":
      return "Epson L565 高速 Wi-Fi 六合一多功能複合機";
    case "moneycounter":
      return "點驗鈔機";
    case "anice-gt5600":
      return "ANICE – GT5600 兩國幣別鑑偽點驗鈔機";
    // case "anice-gt9200":
    //   return "ANICE – GT9200 台幣別鑑偽點驗鈔機";
    case "bojing-auto-ii":
      return "BoJing AUTO II 全自動點驗鈔機";
    case "anice-gt9500":
      return "ANICE – GT9500 六國幣別鑑偽點驗鈔機";
    case "anice-gt9600":
      return "ANICE – GT9600 智能型全功能 台幣別點驗鈔機";
    case "punchclock":
      return "打卡鐘";
    case "anice-gt3600":
      return "ANICE GT3600 四欄位打卡鐘（單色）";
    case "anice-gt3600u":
      return "ANICE GT3600U 四欄位打卡鐘（雙色）";
    case "tr-301":
      return "世尚 TR-301 六欄位打卡鐘";
    case "tr-601":
      return "世尚 TR-601 六欄位打卡鐘";
    case "jm-2010-6":
      return "JM 2010-6 六欄位打卡鐘";
    case "jm-330":
      return "JM-330 人臉指紋三合一考勤機";
    case "papershredder":
      return "碎紙機";
    case "royal-112mx":
      return "ROYAL 112MX 經濟短碎型碎紙機";
    case "resun-c215":
      return "Resun C215 短碎型碎紙機";
    case "royal-1840mx":
      return "ROYAL 1840MX 高保密短碎型碎紙機";
    case "royal-mc14mx":
      return "ROYAL MC14MX 超高保密細碎型碎紙機";
    case "resun-sa3000":
      return "Resun SA3000 短碎型碎紙機";
    case "sysform-3120s":
      return "西德風 SYSFORM 3120S 短碎型碎紙機";
    case "s280a-s300a-s443a":
      return "禾昌 長條型碎紙機";
    case "c280a-c300a-c443a":
      return "禾昌 短碎型碎紙機";
    case "checkmachine":
      return "支票機";
    case "paiyang-p268-p368":
      return "Paiyang P268 數字型支票機 / P368 國字型支票機";
    case "paiyang-p568-p668":
      return "Paiyang P568 數字型支票機 / P668 國字型支票機";
    case "w3000":
      return "世尚 W3000 數字型 / 國字型支票機";
    case "laminator":
      return "護貝機";
    case "resun-lm220-lm320":
      return "Resun LM220(A4)/LM320(A3) 護貝機";
    case "coincountingmachine":
      return "數幣機";
    case "bojing-bj50":
      return "BoJing BJ50 數幣機";
    case "mt-800":
      return "MT 800 商業型分幣機";
    case "printedclock":
      return "印時鐘";
    case "kings-power-kp550":
      return "KINGS POWER KP550 多功能印時鐘";
    case "stapler":
      return "釘書機";
    case "max-bh11f":
      return "MAX BH11F 電動釘書機";
    case "max-eh70f-ii":
      return "MAX EH70F II 電動釘書機";
    case "max-eh110f":
      return "MAX EH110F 電動釘書機";
    case "poshost":
      return "POS觸控主機";
    case "anice-pos-tp-1615":
      return "ANICE POS TP-1615";
    case "ushow-v15-6412h2":
      return "Ushow V15-6412H2";
    case "anice-pos-tp-1715u":
      return "ANICE POS TP-1715U";
    case "anice-pos-tp-7715":
      return "ANICE POS TP-7715";
    case "printer":
      return "熱感式出單機";
    case "hprt-tp805l":
      return "HPRT TP805L";
    case "cp-q88f":
      return "CP Q88F";
    case "itbs-iup900":
      return "Itbs IUP900";
    case "prowill-pd-s8252x":
      return "Prowill PD-S8252X";
    case "prowill-pd-x326":
      return "Prowill PD-X326";
    case "qm3":
      return "QM3";
    case "labelingmachine":
      return "標籤貼紙機";
    case "hprt-lpq58":
      return "HPRT LPQ58";
    case "godex-dt2":
      return "GoDEX DT2";
    case "lp-2":
      return "LP-2";
    case "godex-g500":
      return "GoDEX G500";
    case "godex-g530":
      return "GoDEX G530";
    case "tsc-ttp-345":
      return "TSC TTP-345";
    case "scanner":
      return "掃描器";
    case "rs-80dw/rs-80db":
      return "JMC RS-80DW & RS-80DB 掃描器";
    case "sunmi":
      return "SUNMI 商米掃碼槍";
    case "sunmi-desktop":
      return "SUNMI 商米桌上型掃描器";
    case "rs-9d":
      return "JMC RS 9D 掃描器";
    case "m-6l":
      return "JMC M 6L 桌上型掃描器";
    case "inventorymachine":
      return "盤點機";
    case "inventory-machine":
      return "各式盤點機";
    case "colorribbon":
      return "色帶";
    case "color-ribbon":
      return "各式色帶";
    case "carbonribbon":
      return "碳帶";
    case "carbon-ribbon":
      return "各式碳帶";
    case "sticker":
      return "貼紙";
    case "stickers":
      return "各式貼紙";
    case "rollofpaper":
      return "紙捲";
    case "rollofpapers":
      return "各式紙捲";
    case "cashbox":
      return "錢櫃";
    case "cashboxes":
      return "各式錢櫃";
    case "scale":
      return "磅秤";
    case "dp-105":
      return "DP-105磅秤";
    case "timecard":
      return "考勤卡";
    case "timecards":
      return "各式考勤卡";
    case "timecard-holder":
      return "考勤卡架";
  }
}
