let items = [
  {
    name: "MAX-BH11F",
    title: "MAX BH11F 電動釘書機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/stapler/MAX-BH11F.webp"),
      },
    ],
    intros: [
      { main: "攜帶型、輕巧低噪音；雙向設計，水平或垂直放置皆能裝訂" },
      { main: "雙供電模式：原廠 AC 變壓器、6 顆鹼性三號電池" },
    ],
    specs: [
      {
        title: "裝訂張數",
        text: "2-40 張（64磅）",
      },
      {
        title: "裝訂深度",
        text: "3-15.5 mm",
      },
      {
        title: "裝訂速度",
        text: "0.7秒 / 每支",
      },
      {
        title: "尺寸",
        text: "L 163 x W 69 x H 129 mm",
      },
      {
        title: "重量",
        text: "860 g",
      },
    ],
  },
  {
    name: "MAX-EH70F-II",
    title: "MAX EH70F II 電動釘書機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/stapler/MAX-EH70F-II.webp"),
      },
    ],
    intros: [
      { main: "快速裝訂，大容量針盒，可連續裝訂 5000 份" },
      { main: "具安全保護裝置，避免兒童誤觸" },
      { main: "供電模式：原廠 AC 變壓器" },
    ],
    specs: [
      {
        title: "裝訂張數",
        text: "2-70 張（64磅）",
      },
      {
        title: "裝訂深度",
        text: "自動 6-18 mm / 手動 3-18 mm",
      },
      {
        title: "裝訂速度",
        text: "0.5秒 / 每支",
      },
      {
        title: "尺寸",
        text: "L 229 x W 113 x H 113 mm",
      },
      {
        title: "重量",
        text: "1.5 kg",
      },
    ],
  },
  {
    name: "MAX-EH110F",
    title: "MAX EH110F 電動釘書機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/stapler/MAX-EH110F.webp"),
      },
    ],
    intros: [
      { main: "全新釘盒設計，釘針裝填、卡針清理更方便" },
      { main: "透明前蓋，裝訂位置清楚可見，可大量裝訂，節省工作時間" },
      { main: "供電模式：原廠 AC 變壓器" },
    ],
    specs: [
      {
        title: "裝訂張數",
        text: "2-110 張（70磅）",
      },
      {
        title: "裝訂深度",
        text: "3-23 mm",
      },
      {
        title: "裝訂速度",
        text: "2支 / 秒",
      },
      {
        title: "尺寸",
        text: "L 393 x W 242 x H 210 mm",
      },
      {
        title: "重量",
        text: "7.6 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
