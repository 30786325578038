let items = [
  {
    name: "UM88",
    title: "UM88 電子發票收銀機",
    summary: ["可印：電子發票/收據/二聯發票", "內建出單機及蓄電池"],
    price: "10,000",
    illustrates: ["複雜的事，簡單解決", "餐飲業、飲料業、外燴業者的結帳管家"],
    downloadAddress: `${window.location.origin}/files/UM88-DM.pdf`,
    images: [
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM88.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM88-2.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM88-3.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM88-4.webp"),
      },
    ],
    intros: [
      { main: "內建 SD 卡，所有交易資料都能安全備份" },
      { main: "內建客戶顯示器，方便顧客確認購買品項及消費金額" },
      { main: "具 16 項商品快捷鍵，可設定快速商品，輸入金額後即可結帳" },
      { main: "紙捲盒透明上蓋，隨時掌握紙捲用量，亦可快速更換紙捲" },
      { main: "抽屜式收錢櫃，四層紙鈔格及四層硬幣格，讓收款的分類動作更迅速" },
      { main: "防呆功能：設定價格上限，避免開出天價發票" },
      { main: "密碼權限：設定權限控制使用功能，隨時可變更密碼" },
      { main: "可查詢及列印多種報表：日報表、時期報表、部門報表" },
      { main: "自動偵測是否缺紙及重開作廢發票" },
      { main: "發票自動取號及配號，無需額外設定" },
      { main: "開立發票資料能自動切換：即時上傳模式 / 離線上傳模式" },
      { main: "可外接 WP-560 二聯發票印表機" },
    ],
    specs: [
      {
        title: "商品鍵",
        text: "8x2",
      },
      {
        title: "打印機類型",
        text: "熱感式",
      },
      {
        title: "打印機寬度",
        text: "58mm",
      },
      {
        title: "主螢幕",
        text: "四行文字顯示",
      },
      {
        title: "介面",
        text: "PS/2x1 + RS232x1 + USBx1 + 乙太網 x1 + 錢櫃口 x1",
      },
      {
        title: "機器尺寸",
        text: "L 29.3 x W 18 x H 11.8 mm",
      },
      {
        title: "錢櫃尺寸",
        text: "L 32 x W 24.5 x H 9 mm",
      },
      {
        title: "重量",
        text: "1.51 kg",
      },
    ],
    meta: {
      title:
        "Ushow UM88 電子發票收銀機｜機械式按鍵，簡單開立電子發票｜百揚資訊科技 Ushow POS",
      description:
        "複雜的事，簡單解決，餐飲業、飲料業、外燴業者的結帳管家。內建 SD 卡及客戶顯示器，具 16 項商品快捷鍵，點選商品數量即可快速結帳。紙捲盒透明上蓋，方便掌握用量及更換紙捲；抽屜式錢櫃具四層紙鈔格及四層硬幣格。可設定密碼及列印報表，發票自動取號配號，無需額外設定。",
    },
  },
  {
    name: "UM99",
    title: "UM99 電子發票收銀機",
    summary: ["可印：電子發票/收據", "內建出單機及客顯"],
    price: "10,000",
    illustrates: [
      "結帳管家再升級",
      "食品零售、文教零售、綜合零售業等業者的得力助手",
    ],
    images: [
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM99.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM99-2.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM99-3.webp"),
      },
      {
        url: require("@/assets/images/ecr/einvoicecashier/UM99-4.webp"),
      },
    ],
    intros: [
      { main: "充電電池具警示功能，電力將耗盡時自動跳出通知" },
      { main: "內建客戶顯示器，方便顧客確認購買品項及消費金額" },
      { main: "具 120 項商品快捷鍵、可掀式熱鍵插片，業者能自訂商品" },
      {
        main: "偵測功能：有效偵測是否缺紙，並將偵測到的紙捲送出打印頭，自動重開作廢發票",
      },
      { main: "抽屜式收錢櫃，三層紙鈔格及五層硬幣格，讓收款的分類動作更迅速" },
      { main: "在不同版本應用時，可切換按鍵的定義功能" },
      { main: "密碼權限：設定權限控制使用功能，隨時可變更密碼" },
      { main: "可查詢及列印多種報表：日報表、時期報表、部門報表" },
      { main: "發票自動取號、配號、即時上傳，無需額外設定" },
      { main: "可選擇使用：訓練模式 / 收銀模式" },
    ],
    specs: [
      {
        title: "商品鍵",
        text: "60x2",
      },
      {
        title: "打印機類型",
        text: "熱感式",
      },
      {
        title: "打印機寬度",
        text: "58mm",
      },
      {
        title: "主螢幕",
        text: "四行文字顯示",
      },
      {
        title: "介面",
        text: "PS/2x1 + RS232x1 + USBx1 + 乙太網 x1 + 錢櫃口 x1",
      },
      {
        title: "機器尺寸",
        text: "L 29.3 x W 18 x H 11.8 mm",
      },
      {
        title: "重量",
        text: "1.51 kg",
      },
    ],
    meta: {
      title:
        "Ushow UM99 電子發票收銀機｜可掀式熱鍵插片，最多可設置 120 種商品｜百揚資訊科技 Ushow POS",
      description:
        "結帳管家再升級，食品零售、文教零售、綜合零售等業者的得力助手。電池將耗盡時自動跳出通知，內建客戶顯示器，具 120 項商品快捷鍵，業者能自訂商品。抽屜式錢櫃具三層紙鈔格及五層硬幣格。可設定密碼及列印報表，發票自動取號配號，無需額外設定。",
    },
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
