//ECR
import duplicateUniformInvoice from "./ecr/duplicateUniformInvoice";
import eInvoiceCashier from "./ecr/eInvoiceCashier";
import receipt from "./ecr/receipt";
import triplicateUniformInvoice from "./ecr/triplicateUniformInvoice";

//OA
import businessMachine from "./oa/businessMachine";
import checkMachine from "./oa/checkMachine";
import coinCountingMachine from "./oa/coinCountingMachine";
import laminator from "./oa/laminator";
import moneyCounter from "./oa/moneyCounter";
import paperShredder from "./oa/paperShredder";
import printedClock from "./oa/printedClock";
import punchClock from "./oa/punchClock";
import stapler from "./oa/stapler";

//merch
import printer from "./merch/printer";
import carbonRibbon from "./merch/carbonRibbon";
import cashbox from "./merch/cashbox";
import colorRibbon from "./merch/colorRibbon";
import inventoryMachine from "./merch/inventoryMachine";
import labelingMachine from "./merch/labelingMachine";
import posHost from "./merch/posHost";
import rollOfPaper from "./merch/rollOfPaper";
import scale from "./merch/scale";
import scanner from "./merch/scanner";
import sticker from "./merch/sticker";
import timecard from "./merch/timecard";

//meta
import meta from "./meta/products";

export default {
  //ECR
  duplicateUniformInvoice,
  eInvoiceCashier,
  receipt,
  triplicateUniformInvoice,
  //OA
  businessMachine,
  checkMachine,
  coinCountingMachine,
  laminator,
  moneyCounter,
  paperShredder,
  printedClock,
  punchClock,
  stapler,
  //merch
  printer,
  carbonRibbon,
  cashbox,
  colorRibbon,
  inventoryMachine,
  labelingMachine,
  posHost,
  rollOfPaper,
  scale,
  scanner,
  sticker,
  timecard,
  //meta
  meta,
};
