let items = [
  {
    name: "Resun-LM220-LM320",
    title: "Resun LM220(A4)/LM320(A3) 護貝機",
    price: "10,000",
    version: ["LM220(A4)", "LM320(A3)"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/laminator/Resun-LM220-LM320.webp"),
      },
    ],
    specs: [
      {
        title: "護貝寬度",
        text: "230 mm（A4） / 320 mm（A3）",
      },
      {
        title: "護貝厚度",
        text: "小於 2.0 mm / 小於 2.0 mm",
      },
      {
        title: "溫度範圍",
        text: "100 - 160℃ / 100 - 180℃",
      },
      {
        title: "滾軸數量",
        text: "4支",
      },
      {
        title: "加熱系統",
        text: "燈管加熱",
      },
      {
        title: "電源",
        text: "AC 110V / 60Hz",
      },
      {
        title: "尺寸",
        text: "L 400 x W 200 x H 110 mm / L 500 x W 200 x H 110 mm",
      },
      {
        title: "重量",
        text: "6.2 kg / 7.7 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
