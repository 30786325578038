let items = [
  {
    name: "KINGS-POWER-KP550",
    title: "KINGS POWER KP550 多功能印時鐘",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/printedclock/KINGS-POWER-KP550.webp"),
      },
    ],
    intros: [
      { main: "點陣式印表機" },
      { main: "萬年曆、特殊記號列印" },
      { main: "直向列印、29 個程式內容說明" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 156 x W 174 x H 187 mm",
      },
      {
        title: "重量",
        text: "2.2 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
