let items = [
  // {
  //   name: "ANICE-GT5600",
  //   title: "ANICE – GT5600 兩國幣別鑑偽點驗鈔機",
  //   summary: ["驗鈔幣別：台幣、人民幣"],
  //   price: "10,000",
  //   images: [
  //     {
  //       url: require("@/assets/images/oa/moneycounter/ANICE-GT5600.webp"),
  //     },
  //   ],
  //   intros: [
  //     { main: "液晶螢幕顯示" },
  //     { main: "驗鈔幣別：台幣、人民幣" },
  //     { main: "開機時自動診斷+語音提示驗鈔和顯示（可開啟或關閉）" },
  //     { main: "檢測假鈔：遇假鈔自動停機，聲音提示和顯示" },
  //     { main: "自動計數、張數設定、預置模式、自動辨識、累加模式" },
  //     { main: "全自動磁性、紅外線、紫外線，紙質檢測準確性高" },
  //     {
  //       main: "清點模式：鈔票數量清點，請先按台幣鍵一下，再按清點鍵一下，即可進行點鈔",
  //     },
  //     {
  //       main: "特殊功能：台幣鍵按兩下即出現閃爍功能，是針對千元鈔加強檢測的新功能",
  //     },
  //   ],
  //   specs: [
  //     {
  //       title: "進鈔容量",
  //       text: "200張",
  //     },
  //     {
  //       title: "尺寸",
  //       text: "L 300 x W 250 x H 150 mm",
  //     },
  //     {
  //       title: "重量",
  //       text: "5 kg",
  //     },
  //   ],
  // },
  {
    name: "BoJing-AUTO-II",
    title: "BoJing AUTO II 全自動點驗鈔機",
    summary: ["驗鈔幣別：台幣、人民幣"],
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/moneycounter/BoJing-AUTO-II.webp"),
      },
    ],
    intros: [
      { main: "電容式按鍵" },
      { main: "驗鈔幣別：台幣、人民幣" },
      { main: "依各面額點鈔、驗鈔、計算總金額" },
      { main: "採用可複寫升級改版晶片組，因應日後升級改版等問題" },
      {
        main: "自動計數、預置計數、自動辨識、記憶模式、故障顯示、警示裝置、關機自動診斷",
      },
      { main: "具張數自動檢知裝置，可選購外掛客戶顯示器" },
      {
        main: "精準辨識：特殊油墨偵測，高感磁式油墨檢驗",
      },
      {
        main: "台幣 / 人民幣自動辨識模組，無需切換任何按鍵",
      },
    ],
    specs: [
      {
        title: "點鈔速度",
        text: "900（舊鈔）～1200（新鈔）張 / 分",
      },
      {
        title: "進鈔容量",
        text: "200張以上",
      },
      {
        title: "尺寸",
        text: "L 280 x W 250 x H 170 mm",
      },
      {
        title: "重量",
        text: "4.7 kg",
      },
    ],
  },
  // {
  //   name: "ANICE-GT9200",
  //   title: "ANICE – GT9200 台幣別鑑偽點驗鈔機",
  //   summary: ["驗鈔幣別：台幣"],
  //   price: "10,000",
  //   images: [
  //     {
  //       url: require("@/assets/images/oa/moneycounter/ANICE-GT9200.webp"),
  //     },
  //   ],
  //   intros: [
  //     { main: "驗鈔幣別：台幣" },
  //     { main: "鑑偽功能：磁性檢測、IR紅外線檢測、紫光檢測等鑑偽" },
  //     {
  //       main: "使用功能：混鈔顯示總金額、顯示各幣額明細、張數、分鈔智能檢驗、選版檢驗加強、可設定所需張數、清點模式",
  //     },
  //   ],
  //   specs: [
  //     {
  //       title: "進鈔容量",
  //       text: "200張",
  //     },
  //     {
  //       title: "尺寸",
  //       text: "L 240 x W 300 x H 165 mm",
  //     },
  //     {
  //       title: "重量",
  //       text: "6.3 kg",
  //     },
  //   ],
  // },
  {
    name: "ANICE-GT9500",
    title: "ANICE – GT9500 六國幣別鑑偽點驗鈔機",
    summary: ["驗鈔幣別：台幣、人民幣、美金、歐元、日幣 、港幣"],
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/moneycounter/ANICE-GT9500.webp"),
      },
    ],
    intros: [
      { main: "驗鈔幣別：台幣、人民幣、美金、歐元、日幣、港幣" },
      { main: "專業分鈔、分版、自動計數、計算總金額" },
      { main: "三分鐘自動休眠，附獨立客戶顯示器（顯示：張數＆總金額）" },
    ],
    specs: [
      {
        title: "進鈔容量",
        text: "200張",
      },
      {
        title: "尺寸",
        text: "L 311 x W 268 x H 210 mm",
      },
      {
        title: "重量",
        text: "6 kg",
      },
    ],
  },
  {
    name: "ANICE-GT9600",
    title: "ANICE – GT9600 智能型全功能 台幣別點驗鈔機",
    summary: ["驗鈔幣別：台幣"],
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/moneycounter/ANICE-GT9600.webp"),
      },
    ],
    intros: [
      { main: "驗鈔幣別：台幣" },
      {
        main: "內建6組磁頭、12組紅外線IR偵測系統。高效率馬達，配合高速、高抓力送鈔飛輪及獨特防夾出鈔葉輪，具自動整鈔功能",
      },
      {
        main: "節能設計，指定面額分鈔、預置、張數、累加、加總張數、合計金額及開機自動檢測功能",
      },
    ],
    specs: [
      {
        title: "進鈔容量",
        text: "200張",
      },
      {
        title: "尺寸",
        text: "L 310 x W 246 x H 195 mm",
      },
      {
        title: "重量",
        text: "6 kg",
      },
    ],
  },
  {
    name: "ANICE-GT9800",
    title: "ANICE - GT9800 台幣別點驗鈔機",
    summary: ["驗鈔幣別：台幣"],
    price: "10,000",
    downloadAddress: `${window.location.origin}/files/GT9800-DM.pdf`,
    images: [
      {
        url: require("@/assets/images/oa/moneycounter/ANICE-GT9800.webp"),
      },
    ],
    intros: [
      { main: "驗鈔幣別：台幣" },
      {
        main: "台幣混點面額，計算總金額",
      },
      {
        main: "紙鈔各幣值張數顯示",
      },
      {
        main: "新舊分版、智能檢測、清點模式、累計模式、預置張數",
      },
      {
        main: "鑑偽功能：磁性檢測、IR紅外線檢測、紫光檢測等鑑偽功能",
      },
      {
        main: "一分鐘自動休眠，節能省電",
      },
      {
        main: "附贈客戶顯示器",
      },
    ],
    specs: [
      {
        title: "點鈔速度",
        text: "100張/6秒",
      },
      {
        title: "進鈔容量",
        text: "150張",
      },
      {
        title: "計算顯示",
        text: "1~9999張",
      },
      {
        title: "金額顯示",
        text: "10~999999元",
      },
      {
        title: "尺寸",
        text: "L 31 x W25 x H 17.5cm",
      },
      {
        title: "重量",
        text: "5.5kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
