let items = [
  {
    name: "WP-103S",
    title: "WP-103S 三聯式發票機",
    price: "10,000",
    summary: ["自動偵測卡紙、定位異常、發票用盡，並以燈號 及聲響警示"],
    images: [
      {
        url: require("@/assets/images/ecr/triplicateuniforminvoice/WinPOS-WP-103S.webp"),
      },
    ],
    specs: [
      {
        title: "列印方向",
        text: "高速雙向定位列印",
      },
      {
        title: "列印速度",
        text: "3.6行 / 秒",
      },
      {
        title: "送紙速度",
        text: "25行 / 秒",
      },
      {
        title: "指令",
        text: "ESC/POS",
      },
      {
        title: "狀態感應器",
        text: "自動偵測卡紙、定位異常、發票用盡，並以燈號及聲響警示",
      },
      {
        title: "列印用紙",
        text: "鏈齒三聯式複寫紙",
      },
      {
        title: "介面",
        text: "RS232 + Parallel，可串接客戶顯示器",
      },
      {
        title: "印表機主體尺寸",
        text: "L 192 x W 162 x H 114 mm",
      },
      {
        title: "單據回收槽尺寸",
        text: "L 215 x W 105 x H 155 mm",
      },
      {
        title: "重量",
        text: "3.55 kg",
      },
    ],
  },
  {
    name: "ANICE-PP22",
    title: "ANICE-PP22 三聯式發票機",
    price: "10,000",
    summary: ["隨機附贈「發票管理程式」(含收銀軟體)"],
    images: [
      {
        url: require("@/assets/images/ecr/triplicateuniforminvoice/ANICE-PP22.webp"),
      },
    ],
    intros: [
      { main: "隨機附贈「發票管理程式」，內含收銀軟體" },
      { main: "一次可放入 250 張（一盒）三聯式發票" },
      { main: "高速雙向撞針式印表機，支援 4P 複寫" },
      { main: "自動回捲裝置，存根聯可以收捲存放" },
      { main: "一機雙介面，同時支援串列埠 RS232 & USB" },
      { main: "可列印客戶名稱於發票" },
      { main: "資料備份與回存" },
      { main: "報表彙整轉存 Excel 功能" },
      {
        main: "支援 ESC/POS 控制指令集，並提供 Windows 設備驅動(Delphi、VB、VC)",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
