let items = [
  {
    name: "HPRT-LPQ58",
    title: "HPRT LPQ58",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/HPRT-LPQ58.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "Max. 56 mm" },
      { title: "列印速度", text: "2 ips - 3 ips" },
      { title: "解析度", text: "203 dpi" },
      { title: "尺寸", text: "L 186 x W 133 x H 128 mm" },
      { title: "重量", text: "0.805 kg" },
    ],
  },
  {
    name: "GoDEX-DT2",
    title: "GoDEX DT2",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/GoDEX-DT2.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "54 mm" },
      { title: "列印速度", text: "177 mm/秒" },
      { title: "解析度", text: "203 dpi" },
      { title: "尺寸", text: "L 218 x W 100 x H 172 mm" },
      { title: "重量", text: "1.2 kg" },
    ],
  },
  {
    name: "LP-2",
    title: "LP-2",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/LP-2.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "20-60 mm" },
      { title: "列印速度", text: "Max. 127mm/秒" },
      { title: "解析度", text: "203 dpi" },
      { title: "尺寸", text: "L 190 x W 135 x H 137 mm" },
      { title: "重量", text: "1.04 kg" },
    ],
  },
  {
    name: "GoDEX-G500",
    title: "GoDEX G500",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/GoDEX-G500.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "108 mm" },
      { title: "列印速度", text: "127 mm/秒" },
      { title: "解析度", text: "203 dpi" },
      { title: "尺寸", text: "L 285 x W 226 x H 171 mm" },
      { title: "重量", text: "2.72 kg" },
    ],
  },
  {
    name: "GoDEX-G530",
    title: "GoDEX G530",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/GoDEX-G530.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "105.7 mm" },
      { title: "列印速度", text: "102 mm/秒" },
      { title: "解析度", text: "300 dpi" },
      { title: "尺寸", text: "L 285 x W 226 x H 171 mm" },
      { title: "重量", text: "2.72 kg" },
    ],
  },
  {
    name: "TSC-TTP-345",
    title: "TSC TTP-345",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/labelingmachine/TSC-TTP-345.webp"),
      },
    ],
    specs: [
      { title: "列印寬度", text: "106 mm" },
      { title: "列印速度", text: "127 mm/秒" },
      { title: "解析度", text: "300 dpi" },
      { title: "尺寸", text: "W 213 x H 188 x D 314 mm" },
      { title: "重量", text: "2.8 kg" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
