import { createStore } from "vuex";

const store = createStore({
  state: {
    breadcrumbs: [],
    product: {},
    askItemsChanged: false,
    addSuccessMSG: false,
    meta: {
      name: "",
      title: "",
      description: "",
    },
  },
  getters: {
    metaTitle: (state) => state.meta.title,
    metaDescription: (state) => state.meta.description,
  },
  mutations: {
    PUSHBREADCRUMB(state, item) {
      state.breadcrumbs.push(item);
    },
    CLEARBREADCRUMB(state) {
      state.breadcrumbs = [];
    },
    SETPRODUCT(state, item) {
      state.product = item;
    },
    HIDEADDSUCCESS(state) {
      state.addsuccess = false;
    },
    CHANGEASKITEMS(state) {
      state.askItemsChanged = !state.askItemsChanged;
    },
    SHOWADDSUCCESS(state) {
      state.addSuccessMSG = !state.addSuccessMSG;
    },
    SETMETA(state, item) {
      state.meta = item;
    },
  },
  actions: {
    addBreadCrumb(context, item) {
      context.commit("PUSHBREADCRUMB", item);
    },
    clearBreadCrumbs(context) {
      context.commit("CLEARBREADCRUMB");
    },
    setProduct(context, item) {
      context.commit("SETPRODUCT", item);
    },
    hideAddSuccess(context) {
      context.commit("HIDEADDSUCCESS");
    },
    askItemsChanged(context) {
      context.commit("CHANGEASKITEMS");
    },
    showAddSuccess(context) {
      context.commit("SHOWADDSUCCESS");
    },
    setMeta(context, item) {
      context.commit("SETMETA", item);
    },
  },
});

export default store;
