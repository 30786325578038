let items = [
  {
    value: 0,
    name: "UshowPad",
    text: "Ushow Pad",
    link: "/pos/ushow-pad",
    intro: "簡約美型，不占位<br/>高機動性雲端管理",
    os: "iOS",
    tableManage: true,
    tableOrder: true,
    promotion: true,
    delivery: true,
    cloudAdmin: true,
    comboMode: true,
    tasteMode: true,
    branchStoreManage: true,
    powerSupply: true,
    remoteSupport: false,
    onlinOrder: "upgrade",
    memberManage: "upgrade",
    stockManage: "upgrade",
    eInvoice: "upgrade",
    payment: "upgrade",
    omo: true,
    imageUrl: "Compare/UshowPad.webp",
    images: [{ url: require("@/assets/images/pos/Compare/UshowPad.webp") }],
  },
  {
    value: 1,
    name: "UshowLite",
    text: "Ushow Lite",
    link: "/pos/ushow-lite",
    intro: "一體成型 All in One<br/>簡易操作 Less is More",
    os: "Android",
    tableManage: false,
    tableOrder: false,
    promotion: true,
    delivery: false,
    cloudAdmin: "purchase",
    comboMode: true,
    tasteMode: true,
    branchStoreManage: "purchase",
    powerSupply: false,
    remoteSupport: true,
    onlinOrder: false,
    memberManage: true,
    stockManage: "purchase",
    eInvoice: "purchase",
    payment: "purchase",
    omo: false,
    imageUrl: "Compare/UshowLite.webp",
    images: [{ url: require("@/assets/images/pos/Compare/UshowLite.webp") }],
  },
  {
    value: 2,
    name: "UshowMini",
    text: "Ushow Mini",
    link: "/pos/ushow-mini",
    intro: "內建電池，即開即用<br/>體積輕巧，攜帶方便",
    os: "Android",
    tableManage: false,
    tableOrder: false,
    promotion: true,
    delivery: false,
    cloudAdmin: "purchase",
    comboMode: false,
    tasteMode: false,
    branchStoreManage: "purchase",
    powerSupply: true,
    remoteSupport: true,
    onlinOrder: false,
    memberManage: true,
    stockManage: "purchase",
    eInvoice: "purchase",
    payment: "purchase",
    omo: false,
    imageUrl: "Compare/UshowMini.webp",
    images: [{ url: require("@/assets/images/pos/Compare/UshowMini.webp") }],
  },
  {
    value: 3,
    name: "Ushow",
    text: "Ushow",
    link: "/pos/ushow",
    intro: "高穩定性，靈活擴充<br/>單機功能，完善齊全",
    os: "Windows",
    tableManage: true,
    tableOrder: true,
    promotion: true,
    delivery: true,
    cloudAdmin: "purchase",
    comboMode: true,
    tasteMode: true,
    branchStoreManage: "purchase",
    powerSupply: false,
    remoteSupport: true,
    onlinOrder: "purchase",
    memberManage: true,
    stockManage: true,
    eInvoice: "purchase",
    payment: "purchase",
    omo: false,
    imageUrl: "Compare/Ushow.webp",
    images: [{ url: require("@/assets/images/pos/Compare/Ushow.webp") }],
  },
  {
    value: 4,
    name: "UshowWeb",
    text: "Ushow Web",
    link: "/pos/ushow-web",
    intro: "不限裝置，即開即印<br/>Ushow幫您e次搞定",
    imageUrl: "Compare/UshowWeb.webp",
    images: [{ url: require("@/assets/images/pos/Compare/UshowWeb.webp") }],
  },
];

function getAll() {
  return items;
}

function getCompare() {
  return getRemaining("UshowWeb");
}

function getByValue(value) {
  const type = items.find(function (item) {
    return item.value == value;
  });
  return type;
}

function getRemaining(name) {
  let arr = [];
  items.forEach((i) => {
    arr.push(i);
  });
  let index = arr.findIndex((item) => {
    return item.name == name;
  });
  arr.splice(index, 1);
  return arr;
}

export default { getAll, getByValue, getRemaining, getCompare };
