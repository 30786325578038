let items = [
  {
    name: "Color-Ribbon",
    title: "各式色帶",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/colorribbon/ribbon.webp"),
      },
      {
        url: require("@/assets/images/merch/colorribbon/ribbon-2.webp"),
      },
      {
        url: require("@/assets/images/merch/colorribbon/ribbon-3.webp"),
      },
    ],
    intros: [
      { main: "各式發票機色帶" },
      { main: "歡迎點擊右下方「聯絡我們」或來電詢問！" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
