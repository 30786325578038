let items = [
  {
    name: "ROYAL-112MX",
    title: "ROYAL 112MX 經濟短碎型碎紙機",
    summary: ["可碎：光碟、信用卡"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/ROYAL-112MX.webp"),
      },
    ],
    specs: [
      {
        title: "進紙口寬",
        text: "222mm",
      },
      {
        title: "碎紙細度",
        text: "4 x 33 mm",
      },
      {
        title: "碎紙張數",
        text: "10-12張",
      },
      {
        title: "碎紙速度",
        text: "2.8 m/分",
      },
      {
        title: "碎紙形狀",
        text: "短碎狀",
      },
      {
        title: "碎紙容量",
        text: "15公升",
      },
      {
        title: "電壓標準",
        text: "110V-120V/50Hz 3A/330W",
      },
      {
        title: "尺寸",
        text: "W 310 x H 430 x D 200 mm",
      },
      {
        title: "重量",
        text: "8 kg",
      },
    ],
  },
  {
    name: "Resun-C215",
    title: "Resun C215 短碎型碎紙機",
    summary: ["可碎：光碟、信用卡"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/Resun-C215.webp"),
      },
    ],
    specs: [
      {
        title: "碎紙細度",
        text: "4 x 40 mm",
      },
      {
        title: "碎紙張數",
        text: "15張 / 次 (70磅)",
      },
      {
        title: "碎紙容量",
        text: "26公升",
      },
      {
        title: "尺寸",
        text: "L 280 x W 390 x H 525 mm",
      },
      {
        title: "重量",
        text: "12.5kg",
      },
      {
        title: "安全設計",
        text: "滿紙 / 開門 / 過熱 / 夾紙 自動停機",
      },
    ],
  },
  {
    name: "ROYAL-1840MX",
    title: "ROYAL 1840MX 高保密短碎型碎紙機",
    summary: ["可碎：光碟、信用卡"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/ROYAL-1840MX.webp"),
      },
    ],
    intros: [{ main: "雙入口設計、低分貝靜音碎紙" }],
    specs: [
      {
        title: "碎紙細度",
        text: "4 x 25 mm",
      },
      {
        title: "碎紙張數",
        text: "20張",
      },
      {
        title: "碎紙容量",
        text: "36公升",
      },
      {
        title: "電壓標準",
        text: "110V-120V/50Hz 3A/330W",
      },
      {
        title: "尺寸",
        text: "L 400 x W 312 x H 650 mm",
      },
      {
        title: "重量",
        text: "19 kg",
      },
    ],
  },
  {
    name: "ROYAL-MC14MX",
    title: "ROYAL MC14MX 超高保密細碎型碎紙機",
    summary: ["可碎：光碟、信用卡、小型釘書針"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡、小型釘書針"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/ROYAL-MC14MX.webp"),
      },
    ],
    intros: [
      {
        main: "雙入口設計、低噪音設計",
      },
    ],
    specs: [
      {
        title: "進紙口寬",
        text: "230 mm",
      },
      {
        title: "碎紙細度",
        text: "4 x 10 mm",
      },
      {
        title: "碎紙張數",
        text: "15張 (A4/70磅)",
      },
      {
        title: "電壓標準",
        text: "110V-120V/50Hz 3A/330W",
      },
      {
        title: "尺寸",
        text: "L 396 x W 323 x H 653 mm",
      },
      {
        title: "重量",
        text: "14.2 kg",
      },
      {
        title: "安全設計",
        text: "卡紙自動退紙、滿紙觀察窗口、滿紙過載斷電保護",
      },
    ],
  },
  {
    name: "Resun-SA3000",
    title: "Resun SA3000 短碎型碎紙機",
    summary: ["可碎：光碟、信用卡"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/Resun-SA3000.webp"),
      },
    ],
    intros: [{ main: "雙刀具設計" }],
    specs: [
      {
        title: "進紙口寬",
        text: "310 mm",
      },
      {
        title: "碎紙細度",
        text: "3 x 28 mm",
      },
      {
        title: "碎紙張數",
        text: "17張 (A4/70磅)",
      },
      {
        title: "碎紙容量",
        text: "35公升",
      },
      {
        title: "尺寸",
        text: "L 450 x W 325 x H 675 mm",
      },
      {
        title: "重量",
        text: "24 kg",
      },
    ],
  },
  {
    name: "SYSFORM-3120S",
    title: "西德風 SYSFORM 3120S 短碎型碎紙機",
    summary: ["可碎：光碟、信用卡、釘書針、迴紋針"],
    price: "10,000",
    illustrates: ["可碎：光碟、信用卡、釘書針、迴紋針"],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/SYSFORM-3120S.webp"),
      },
    ],
    intros: [
      { main: "機頭機身可分離，具腳輪、安全保護板、金屬機殼及吸音木箱" },
    ],
    specs: [
      {
        title: "進紙口寬",
        text: "310 mm",
      },
      {
        title: "碎紙細度",
        text: "3.9 x 36 mm",
      },
      {
        title: "碎紙張數",
        text: "20張",
      },
      {
        title: "碎紙容量",
        text: "65公升",
      },
      {
        title: "尺寸",
        text: "L 487 x W 327 x H 810 mm",
      },
      {
        title: "重量",
        text: "45 kg",
      },
      {
        title: "安全設計",
        text: "滿紙 / 開門 / 過熱 自動停機、卡紙自動倒退",
      },
    ],
  },
  {
    name: "S280A-S300A-S443A",
    title: "禾昌 長條型碎紙機",
    summary: [" "],
    price: "10,000",
    illustrates: [" "],
    version: ["S280A", "S300A", "S443A"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/C280A-C300A-C443A.webp"),
      },
    ],
    specs: [
      {
        title: "進紙口寬",
        text: "280mm（B4） / 300mm（B4） / 440mm（B4）",
      },
      {
        title: "碎紙張數",
        text: "10~12 張（A4 / 70磅） / 10~12 張（A4 / 70磅） / 18~20 張（A4 / 70磅）",
      },
      {
        title: "尺寸",
        text: "L 410 x W 300 x H 730 mm / L 410 x W 300 x H 730 mm /  L 600 x W 370 x H 930 mm",
      },
      {
        title: "重量",
        text: "29 kg / 32.5kg / 42kg",
      },
    ],
  },
  {
    name: "C280A-C300A-C443A",
    title: "禾昌 短碎型碎紙機",
    summary: [" "],
    price: "10,000",
    illustrates: [" "],
    version: ["C280A", "C300A", "C443A"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/papershredder/C280A-C300A-C443A.webp"),
      },
    ],
    specs: [
      {
        title: "進紙口寬",
        text: "280mm（B4） / 300mm（B4） / 440mm（B4）",
      },
      {
        title: "碎紙張數",
        text: "6~8 張（A4 / 70磅） / 6~8 張（A4 / 70磅） / 18~20 張（A4 / 70磅）",
      },
      {
        title: "尺寸",
        text: "L 410 x W 300 x H 730 mm / L 410 x W 300 x H 730 mm /  L 600 x W 370 x H 930 mm",
      },
      {
        title: "重量",
        text: "29 kg / 32.5kg / 42kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
