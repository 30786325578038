let items = [
  {
    name: "ANICE-POS-TP-1615",
    title: "ANICE POS TP-1615",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/poshost/Anice-POS-TP-1715U&1000BP.webp"),
      },
    ],
    specs: [
      {
        title: "處理器",
        text: "Intel® Elkhart Lake Celeron® J6412 (1.5M Cache, up to 2.60 GHz)",
      },
      {
        title: "記憶體",
        text: "DDR4 SO-DIMM 8G",
      },
      {
        title: "作業系統",
        text: "Windows 10，64位元",
      },
      {
        title: "螢幕尺寸",
        text: "15吋",
      },
      {
        title: "觸控方式",
        text: "5線電阻式或電容式",
      },
      {
        title: "硬碟",
        text: "128G SSD",
      },
      {
        title: "USB",
        text: "5xUSB2.0 & 1xUSB3.0",
      },
      {
        title: "COM",
        text: "3xCOM(DB9) & 1xCOM(USB)",
      },
      {
        title: "網路",
        text: "RJ-45",
      },
      {
        title: "電源",
        text: "12V/60W（外接式變壓器 AC 100～240 V）",
      },
      {
        title: "尺寸",
        text: "W 355 x H 303 x D 205 mm",
      },
      {
        title: "重量",
        text: "5 kg",
      },
    ],
  },
  {
    name: "ANICE-POS-PP-8400",
    title: "ANICE POS PP-8400",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/poshost/ANICE-POS-PP8400.webp"),
      },
    ],
    specs: [
      {
        title: "處理器",
        text: "Intel® Elkhart Lake Refresh Celeron®J6412 11代",
      },
      {
        title: "記憶體",
        text: "DDR4 4G RAM / Up to 8GB",
      },
      {
        title: "作業系統",
        text: "Windows 10，64位元",
      },
      {
        title: "螢幕尺寸",
        text: "15吋",
      },
      {
        title: "觸控方式",
        text: "電容式",
      },
      {
        title: "硬碟",
        text: "128G SSD",
      },
      {
        title: "USB",
        text: "4xUSB2.0 & 2xUSB3.0",
      },
      {
        title: "COM",
        text: "2xCOM",
      },
      {
        title: "網路",
        text: "RJ-45",
      },
      {
        title: "電源",
        text: "12V/60W（外接式變壓器 AC 100～240 V）",
      },
      {
        title: "尺寸",
        text: "W 345 x H 304.5 x D 194 mm",
      },
      {
        title: "重量",
        text: "7.1 kg",
      },
    ],
  },
  {
    name: "ANICE-POS-TP-1715U",
    title: "ANICE POS TP-1715U",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/poshost/Anice-POS-TP-1715U&1000BP.webp"),
      },
    ],
    specs: [
      {
        title: "處理器",
        text: "Intel® Core™ i5-6360U Processor (4M, Max. 3.1 GHz)",
      },
      {
        title: "記憶體",
        text: "DDR4 4G RAM / Up to 32GB",
      },
      {
        title: "作業系統",
        text: "Windows 10，64位元",
      },
      {
        title: "螢幕尺寸",
        text: "15吋",
      },
      {
        title: "觸控方式",
        text: "5線電阻式或電容式",
      },
      {
        title: "硬碟",
        text: "128G SSD",
      },
      {
        title: "USB",
        text: "2xUSB2.0 & 4xUSB3.0",
      },
      {
        title: "COM",
        text: "3xCOM (DB9)",
      },
      {
        title: "網路",
        text: "RJ-45",
      },
      {
        title: "電源",
        text: "12V/90W（外接式變壓器 AC 100～240 V）",
      },
      {
        title: "尺寸",
        text: "W 355 x H 303 x D 205 mm",
      },
      {
        title: "重量",
        text: "4.6 kg",
      },
    ],
  },
  {
    name: "ANICE-POS-TP-7715",
    title: "ANICE POS TP-7715",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/merch/poshost/ANICE-POS-TP-7715.webp"),
      },
    ],
    specs: [
      {
        title: "處理器",
        text: "Intel® i5-6500TE（Up to 3.3 GHz, Quad Core, 4M Cache）",
      },
      {
        title: "記憶體",
        text: "DDR4 8G RAM / Up to 32GB",
      },
      {
        title: "作業系統",
        text: "Windows 10，64位元",
      },
      {
        title: "螢幕尺寸",
        text: "15吋",
      },
      {
        title: "觸控方式",
        text: "5線電阻式或電容式",
      },
      {
        title: "硬碟",
        text: "128G SSD",
      },
      {
        title: "USB",
        text: "2xUSB2.0 & 4xUSB3.0",
      },
      {
        title: "COM",
        text: "4xCOM (2xDB9 + 2x8P8C)",
      },
      {
        title: "網路",
        text: "RJ-45",
      },
      {
        title: "電源",
        text: "12V/60W（外接式變壓器 AC 100～240 V）",
      },
      {
        title: "尺寸",
        text: "W 356 x H 318 x D 207 mm",
      },
      {
        title: "重量",
        text: "7.1 kg",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
