let items = [
  {
    name: "ANICE-GT3600",
    title: "ANICE GT3600 四欄位打卡鐘（單色）",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/ANICE-GT3600.webp"),
      },
    ],
    intros: [
      { main: "雙色背光液晶，遲到會變橘黃色" },
      { main: "自動移位，內建音樂響鈴" },
      { main: "內建蓄電池" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 135 x W 120 x H 190 mm",
      },
      {
        title: "重量",
        text: "1.6 kg",
      },
    ],
  },
  {
    name: "ANICE-GT3600U",
    title: "ANICE GT3600U 四欄位打卡鐘（雙色）",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/ANICE-GT3600U.webp"),
      },
    ],
    intros: [
      { main: "四欄位雙色打卡鐘，可黑紅變色" },
      { main: "大型 LCD 背光液晶螢幕" },
      { main: "自動偵測考勤卡正反面，避免誤打" },
      { main: "自動吸卡及退卡" },
      { main: "停電內部記憶功能" },
      { main: "可設定 18 組變色 (紅變黑)、42組響鈴、18組自動移位" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 145 x W 112 x H 178 mm",
      },
      {
        title: "重量",
        text: "1.6 kg",
      },
    ],
  },
  {
    name: "TR-301",
    title: "世尚 TR-301 六欄位打卡鐘",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/TR-301.webp"),
      },
    ],
    intros: [
      { main: "時鐘系統：石英振盪系統" },
      { main: "音樂響鈴：可外接" },
      { main: "打印顏色：黑紅（遲到為紅色）" },
      { main: "卡面辨識：具正反面辨識" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 185 x W 215 x H 123 mm",
      },
      {
        title: "重量",
        text: "1.8 kg",
      },
    ],
  },
  {
    name: "TR-601",
    title: "世尚 TR-601 六欄位打卡鐘",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/TR-601.webp"),
      },
    ],
    intros: [
      { main: "雙色列印（遲到打印紅色）" },
      { main: "大型液晶螢幕" },
      { main: "自動偵測考勤卡正反面，避免誤打" },
      { main: "自動吸卡、退卡" },
      { main: "具萬年曆，閏年、閏月，皆可自動調整" },
      { main: "可自動移位、設定上下班響鈴、外接響鈴" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 185 x W 245 x H 123 mm",
      },
      {
        title: "重量",
        text: "1.8 kg",
      },
    ],
  },
  {
    name: "JM-2010-6",
    title: "JM 2010-6 六欄位打卡鐘",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/JM-2010-6.webp"),
      },
    ],
    intros: [
      { main: "上下班音樂鈴聲提示" },
      { main: "上下班自動移位、六欄位打印" },
      { main: "自動偵測考勤卡正反面，避免誤打" },
      { main: "四位數密碼鎖定，防止他人修改" },
      { main: "欄位 LED 指示，上下班更清楚" },
      { main: "多組上下班時間設定，響鈴輸出時間可調整" },
    ],
    specs: [
      {
        title: "尺寸",
        text: "L 200 x W 130 x H 210 mm",
      },
      {
        title: "重量",
        text: "1.8 kg",
      },
    ],
  },
  {
    name: "JM-330",
    title: "JM-330 人臉指紋三合一考勤機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/punchclock/JM-330.webp"),
      },
    ],
    intros: [
      { main: "人臉 + 指紋 + 密碼，三種打卡方式" },
      { main: "人臉、指紋辨識速度 < 0.8秒" },
      { main: "內建考勤系統，無需安裝軟體" },
      { main: "內建報表含：員工資訊表、考勤設置表、員工排班表、考勤彙總表" },
      { main: "可使用USB匯出EXCEL報表、考勤紀錄TXT檔" },
      { main: "尺寸：L 80 x W 170 x H 130 mm" },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
