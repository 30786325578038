let items = [
  {
    name: "Panasonic_KX-MB2128TW_KX-MB2178TW",
    title: "Panasonic 雷射多功能事務機",
    price: "10,000",
    version: ["KX-MB2128TW", "KX-MB2178TW"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/businessmachine/Panasonic-KX-MB2128TW.webp"),
      },
    ],
    intros: [
      { main: "26 ppm 列印 / 26 cpm 影印 (A4)" },
      { main: "24 ppm 快速雙面列印 (A4)" },
      { main: "列印解析度：600 dpi" },
      { main: "掃描解析度：19200 dpi" },
      { main: "35張 ADF、250張標準紙匣" },
      { main: "2行、16字元背光 LCD 顯示器" },
      { main: "SUPER G3 FAX 支援預防錯誤撥號模式" },
      { main: "內建高速 USB 連接介面" },
    ],
  },
  {
    name: "Brother_MFC_L2740DW_L2700DW_L2700D",
    title: "Brother MFC 平台式雷射多功能事務機",
    price: "10,000",
    version: ["L2740DW", "L2700DW", "L2700D"],
    askVersion: [],
    images: [
      {
        url: require("@/assets/images/oa/businessmachine/Brother-MFC-L2740DW.webp"),
      },
    ],
    intros: [
      { main: "功能：列印、掃描、影印、傳真" },
      { main: "自動雙面列印" },
      { main: "網路功能：有線 / 無線" },
      { main: "列印解析度：2400 x 600 dpi" },
      { main: "A4 列印速度：30 ppm" },
      { main: "記憶體：64 MB" },
      { main: "螢幕：2.7” 彩色觸控螢幕" },
    ],
  },
  {
    name: "Brother_FAX-2840",
    title: "Brother FAX-2840 平台式多功能複合機",
    price: "10,000",
    images: [
      {
        url: require("@/assets/images/oa/businessmachine/Brother-FAX-2840.webp"),
      },
    ],
    intros: [
      { main: "解析度：2400 x 600 dpi，高品質列印" },
      { main: "無紙接收傳真，最多可儲存 400 頁" },
      { main: "傳真速度高達 33.6 Kbps" },
      { main: "配備 20 張 ADF 自動進紙器，工作更有效率" },
      { main: "可選擇列印量約 2600 頁的高容量碳粉匣，節省列印成本" },
    ],
  },
  {
    name: "Epson_L565_Wi-Fi",
    title: "Epson L565 高速 Wi-Fi 六合一多功能複合機",
    price: "10,000",
    images: [
      { url: require("@/assets/images/oa/businessmachine/EPSON-L565.webp") },
    ],
    intros: [
      { main: "掃描 / 影印 / 列印 / Wi-Fi / ADF / 傳真" },
      { main: "目標客戶：月印量 2000 張，SOHO 小型辦公室 / 中小企業" },
      {
        main: "商品應用：壽險（企劃書）、房仲（海報、傳單）、補教（講義）、診所（病歷資料）、警局（案件資料）",
      },
      {
        main: "商品特色：",
        detail: [
          "省 - 12,000 張大印量",
          "快 - 33/15 ppm 高速 Wi-Fi 列印",
          "美 - 原廠墨水列印品質，免堵塞、免漏墨、免歸零",
        ],
      },
      { main: "原廠保固最安心" },
      { main: "購買主機享有1年原廠到府取送及代用機保固服務" },
      {
        main: "加購一組墨水，並上網登錄 www.epson.com.tw/ciss_svc，享延長第二年保固優惠",
      },
    ],
  },
];

function getAll() {
  return items;
}

function getByName(name) {
  const type = items.find(function (item) {
    return item.name == name;
  });
  return type;
}

function getRemaining(name) {
  let index = items.findIndex((item) => {
    return item.name == name;
  });
  items.splice(index, 1);
  return items;
}

export default { getAll, getByName, getRemaining };
